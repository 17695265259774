import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    
    <div className="min-h-screen pt-16 bg-pattensblue">

      <div className="h-screen my-container">
        <h1 className="py-4 text-2xl text-center md:text-4xl">404: Not Found</h1>

        <p className="text-center">You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>

    </div>
  </Layout>
)

export default NotFoundPage
